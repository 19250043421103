<template>
  <div>
    <b-card style="margin-bottom: -1rem">
      <b-row>
        <b-col
          cols="8"
        >
          <h4>
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            마켓목록
          </h4>
          <hr>
          <div>
            <b-row>
              <b-col>
                <b-form-group
                  label="메뉴"
                  label-for="search-menu"
                >
                  <b-button-group size="sm">
                    <b-button
                      size="sm"
                      style="margin-right: 3px; width: 5.5rem"
                      :variant="selectedMenuIdx === 1 ? 'primary' : 'outline-secondary'"
                      @click="selectMenu('Sports-Prematch', 1)"
                    >
                      프리매치
                    </b-button>
                    <b-button
                      size="sm"
                      style="margin-right: 3px; width: 5.5rem"
                      :variant="selectedMenuIdx === 2 ? 'primary' : 'outline-secondary'"
                      @click="selectMenu('Sports-Special', 2)"
                    >
                      스페셜
                    </b-button>
                    <b-button
                      size="sm"
                      style="margin-right: 3px; width: 5.5rem"
                      :variant="selectedMenuIdx === 3 ? 'primary' : 'outline-secondary'"
                      @click="selectMenu('Sports-Inplay', 3)"
                    >
                      인플레이
                    </b-button>
                  </b-button-group>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="종목"
                  label-for="search-sport"
                >
                  <b-button-group size="sm">
                    <b-button
                      size="sm"
                      style="margin-right: 3px; width: 5.5rem"
                      :variant="selectedSportIdx === 1 ? 'primary' : 'outline-secondary'"
                      @click="selectSport('soccer', 1)"
                    >
                      축구
                    </b-button>
                    <b-button
                      size="sm"
                      style="margin-right: 3px; width: 5.5rem"
                      :variant="selectedSportIdx === 2 ? 'primary' : 'outline-secondary'"
                      @click="selectSport('basketball', 2)"
                    >
                      농구
                    </b-button>
                    <b-button
                      size="sm"
                      style="margin-right: 3px; width: 5.5rem"
                      :variant="selectedSportIdx === 3 ? 'primary' : 'outline-secondary'"
                      @click="selectSport('baseball', 3)"
                    >
                      야구
                    </b-button>
                    <b-button
                      size="sm"
                      style="margin-right: 3px; width: 5.5rem"
                      :variant="selectedSportIdx === 4 ? 'primary' : 'outline-secondary'"
                      @click="selectSport('tennis', 4)"
                    >
                      테니스
                    </b-button>
                    <b-button
                      size="sm"
                      style="margin-right: 3px; width: 5.5rem"
                      :variant="selectedSportIdx === 5 ? 'primary' : 'outline-secondary'"
                      @click="selectSport('volleyball', 5)"
                    >
                      배구
                    </b-button>
                    <b-button
                      size="sm"
                      style="margin-right: 3px; width: 5.5rem"
                      :variant="selectedSportIdx === 6 ? 'primary' : 'outline-secondary'"
                      @click="selectSport('football', 6)"
                    >
                      풋볼
                    </b-button>
                    <b-button
                      size="sm"
                      style="margin-right: 3px; width: 5.5rem"
                      :variant="selectedSportIdx === 7 ? 'primary' : 'outline-secondary'"
                      @click="selectSport('hockey', 7)"
                    >
                      하키
                    </b-button>
                    <b-button
                      size="sm"
                      style="margin-right: 3px; width: 5.5rem"
                      :variant="selectedSportIdx === 8 ? 'primary' : 'outline-secondary'"
                      @click="selectSport('esports', 8)"
                    >
                      E스포츠
                    </b-button>
                  </b-button-group>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-table
              responsive
              show-empty
              hover
              small
              striped
              empty-text="No matching records found"
              :items="filteredItems"
              :fields="fields"
              :tbody-tr-class="getRowClass"
              style="text-align: center !important; font-size: small"
              @row-clicked="addItemToGameSetCart"
            >
              <template #cell(no)="data">
                {{ data.item.idx }}
              </template>
            </b-table>
          </div>
        </b-col>
        <b-col
          cols="4"
        >
          <h4>
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            조합배팅추가
          </h4>
          <hr>
          <div>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="마켓조합"
                  label-for="marketId"
                >
                  <b-input-group>
                    <b-form-input
                      id="marketId"
                      v-model="marketIds"
                      placeholder="마켓조합"
                      class="cursor-pointer font-small-3"
                      style="color:rgb(229,166,48)"
                      readonly
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="마켓조합명"
                  label-for="marketId"
                >
                  <b-input-group>
                    <b-form-input
                      id="marketId"
                      v-model="marketNames"
                      placeholder="마켓조합"
                      class="cursor-pointer font-small-3"
                      style="color:rgb(229,166,48)"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          filteredMarketId <br> {{ marketIds }}
          filteredMarketName <br> {{ marketNames }}
          gameCombinationCart <br> {{ gameCombinationCart }}
          <!-- filteredItems <br> {{ filteredItems }} -->
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormGroup, BButtonGroup, BButton,
  BBadge,
  BTable,
  BCol,
  BRow,
  BFormSelect,

  BFormSelectOption,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'

import { FETCH_GAME_SET_ODDS_MARKET, FETCH_GAME_SET_MENU, FETCH_GAME_SET_ITEM, FETCH_GAME_SET_ODDS_CUSTOM, FETCH_GAME_SET_COMBINATION } from '@/store/game/action'

import { UPDATE_GAME_SET_COMBINATION } from '@/store/game/mutation'

const gameStore = createNamespacedHelpers('gameStore')

export default {
  name: 'GameSettingsTabCombination2',
  components: {
    BCard,
    BFormInput,
    BButtonGroup,
    BButton,
    BBadge,
    BTable,
    BRow,
    BCol,
    BFormSelect,
    BFormGroup,
    BFormSelectOption,
  },
  directives: {
    Ripple,
  },
  props: {
    site: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),

      selectedMenu: 'Sports-Prematch',
      selectedMenuIdx: 1,
      selectedSport: 'soccer',
      selectedSportIdx: 1,
      marketIds: null,
      marketNames: null,
      gameCombinationCart: [],
      option1: [],
      option2: [],
      option3: [],
      option4: [],
      option5: [
        { value: '', text: '선택' },
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
      ],
      insertData: {
        site: this.site,
        menu: null,
        item: null,
        oddsType1: null,
        oddsType2: null,
        fold: null,
      },
      selected1: { value: '', text: '선택' },
      selected2: { value: '', text: '선택' },
      selected3: { value: '', text: '선택' },
      selected4: { value: '', text: '선택' },
      selected5: { value: '', text: '선택' },
      fetchGameSettCombination2: [
        {
          no: 1,
          type: 'sports',
          menuId: '01',
          menuName: 'prematch',
          itemId: '001',
          itemName: 'soccer',
          combinationRule: 'Match Winner + Asian Handicap',
          combinationRuleDesc: '승무패 + 핸디캡',
          combinationMsg: '축구 승무패, 핸디캡은 조합이 불가능합니다.',
        },
        {
          no: 2,
          type: 'sports',
          menuId: '01',
          menuName: 'prematch',
          itemId: '001',
          itemName: 'soccer',
          combinationRule: 'Match Winner + Goals Over/Under',
          combinationRuleDesc: '승무패 + 오버언더',
          combinationMsg: '축구 승무패, 오버언더는 조합이 불가능합니다.',
        },
        {
          no: 3,
          type: 'sports',
          menuId: '01',
          menuName: 'prematch',
          itemId: '001',
          itemName: 'soccer',
          combinationRule: 'Asian Handicap + Goals Over/Under',
          combinationRuleDesc: '핸디캡 + 오버언더',
          combinationMsg: '축구 핸디캡, 오버언더는 조합이 불가능합니다.',
        },
      ],
      fields2: [
        {
          key: 'index', sortable: false, label: 'No.',
        },
        {
          key: 'type', sortable: false, label: '타입',
        },
        {
          key: 'menuId', sortable: false, label: 'Code',
        },
        {
          key: 'menuName', sortable: false, label: '메뉴명',
        },
        {
          key: 'itemId', sortable: false, label: 'Code',
        },
        {
          key: 'itemName', sortable: false, label: '종목명',
        },
        {
          key: 'combinationRule', sortable: false, label: '조합불가 로직',
        },
        {
          key: 'combinationRuleDesc', sortable: false, label: '조합불가 규칙',
        },
        {
          key: 'combinationMsg', sortable: false, label: '조합불가 메세지',
        },
      ],

      fields: [
        { key: 'marketId', sortable: false, label: 'CODE' },
        { key: 'marketName', sortable: false, label: '유형명' },
        { key: 'marketNameCustom', sortable: false, label: '유형명(한글)' },
      ],
    }
  },
  computed: {
    ...gameStore.mapGetters({
      fetchGameSetMenu: 'fetchGameSetMenu',
      fetchGameSetItem: 'fetchGameSetItem',
      fetchGameSetOddsMarket: 'fetchGameSetOddsMarket',
      fetchGameSetOddsCustom1: 'fetchGameSetOddsCustom',
      fetchGameSetOddsCustom2: 'fetchGameSetOddsCustom',
      fetchGameSettCombination: 'fetchGameSettCombination',
      updateGameSettCombination: 'updateGameSettCombination',
    }),
    // 검색필터된 Items
    filteredItems() {
      // menuName과 itemName의 우선순위 배열 정의
      const menuNameOrder = ['Sports-Prematch', 'Sports-Inplay', 'Sports-Special']
      const itemNameOrder = ['soccer', 'basketball', 'baseball', 'tennis', 'volleyball', 'football', 'hockey', 'esports']

      // selected 상태 설정
      const filteredData = this.fetchGameSetOddsMarket
        .map(item => ({
          ...item,
          selected: this.gameCombinationCart.some(cartItem => cartItem.idx === item.idx),
        }))
        .sort((a, b) => {
        // 1. selected가 true인 항목을 먼저 정렬
          if (b.selected !== a.selected) return b.selected - a.selected

          // 2. menuName을 우선순위 배열에 따라 정렬
          const menuAIndex = menuNameOrder.indexOf(a.menuName)
          const menuBIndex = menuNameOrder.indexOf(b.menuName)
          if (menuAIndex !== menuBIndex) return menuAIndex - menuBIndex

          // 3. itemName을 우선순위 배열에 따라 정렬
          const itemAIndex = itemNameOrder.indexOf(a.itemName.toLowerCase())
          const itemBIndex = itemNameOrder.indexOf(b.itemName.toLowerCase())
          if (itemAIndex !== itemBIndex) return itemAIndex - itemBIndex

          // 4. itemName이 같다면 marketId로 오름차순 정렬
          return a.marketId - b.marketId
        })

      return filteredData
    },
    // filteredMarketId() {
    //   // gameCombinationCart의 marketId를 문자열로 변환
    //   return this.gameCombinationCart.map(item => item.marketId).join(', ')
    // },
    // filteredMarketName() {
    //   // marketNameCustom이 있으면 사용, 없으면 marketName을 사용하여 문자열 조합
    //   return this.gameCombinationCart
    //     .map(item => item.marketNameCustom || item.marketName)
    //     .join(', ')
    // },
  },
  watch: {
    site: {
      handler(event) {
        this.fetchData()
        // localStorage.setItem('authSiteSelected', event)
        // this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
      },
      immediate: false,
      deep: false,
    },
    gameCombinationCart() {
      this.getMarketInfo()
    },
    selected1() {
      this.updateOption2()
      this.updateOption3()
      this.updateOption4()
    },
    selected2() {
      this.updateOption3()
      this.updateOption4()
    },
    selected3() {
      this.updateOption4()
    },
    selectedMenuIdx() {
      this.resetCart()
    },
    selectedSportIdx() {
      this.resetCart()
    },

  },
  created() {
  },
  mounted() {
    // this.fetchData()
  },
  setup() {
  },
  methods: {
    ...gameStore.mapActions({
      $fetchGameSetMenu: FETCH_GAME_SET_MENU,
      $fetchGameSetItem: FETCH_GAME_SET_ITEM,
      $fetchGameSetOddsMarket: FETCH_GAME_SET_ODDS_MARKET,
      $fetchGameSetOddsCustom1: FETCH_GAME_SET_ODDS_CUSTOM,
      $fetchGameSetOddsCustom2: FETCH_GAME_SET_ODDS_CUSTOM,
      $fetchGameSettCombination: FETCH_GAME_SET_COMBINATION,
      $updateGameSettCombination: UPDATE_GAME_SET_COMBINATION,
    }),
    async fetchData() {
      const mySite = this.site
      await this.$fetchGameSetOddsMarket({
        site: mySite,
        menuName: this.selectedMenu,
        sports: this.selectedSport,
        useYn: false,
      })

      // const mySite = this.site
      // await this.$fetchGameSetMenu({
      //   site: mySite,
      // })
      // await this.$fetchGameSetItem({
      //   site: mySite,
      // })
      // await this.$fetchGameSetOddsCustom1({
      //   site: mySite,
      // })
      // await this.$fetchGameSetOddsCustom2({
      //   site: mySite,
      // })
      // await this.$fetchGameSettCombination({
      //   site: mySite,
      // })
      // console.log(this.fetchGameSetMenu)
      // console.log(this.fetchGameSetMenu)
      // console.log(this.fetchGameSetItem)
      // console.log(this.fetchGameSetOddsCustom)
      // console.log(this.fetchGameSettCombination)

      this.updateOption1()
    },
    //
    addItemToGameSetCart(item) {
      const index = this.gameCombinationCart.findIndex(cartItem => cartItem.idx === item.idx)
      if (index === -1) {
        this.gameCombinationCart.push(item)
      } else {
        this.gameCombinationCart.splice(index, 1)
      }
    },
    //
    getRowClass(item) {
    // Return 'selected-row' class if item is in gameCombinationCart
      return this.gameCombinationCart.some(cartItem => cartItem.idx === item.idx) ? 'highlight-row' : ''
    },
    //
    async selectSport(sports, index) {
      this.selectedSport = sports
      this.selectedSportIdx = index
      await this.fetchData()
    },
    async selectMenu(menu, index) {
      this.selectedMenu = menu
      this.selectedMenuIdx = index
      await this.fetchData()
    },
    getMarketInfo() {
      // gameCombinationCart에서 marketId와 marketName 또는 marketNameCustom을 추출
      this.marketIds = this.gameCombinationCart.map(item => item.marketId).join(', ')
      this.marketNames = this.gameCombinationCart.map(item => item.marketNameCustom || item.marketName).join(', ')
    },
    resetCart() {
      this.gameCombinationCart = []
    },

    async submit(target) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target)
        }
      })
    },
    // confirm Yes
    async confirmYes(target) {
      const mySite = this.site
      try {
        if (target === 'updateGameSettCombination') {
          await this.$updateGameSettCombination({
            site: mySite,
            // store의 updated 된 param을 사용한다.
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
        })
      }
    },
    clearSelect() {
    //   console.log(this.selected2)
    //   this.selected1.text = '선택'
    //   this.selected2 = { value: '', text: '선택' }
    //   this.selected3 = { value: '', text: '선택' }
    //   this.selected4 = { value: '', text: '선택' }
    //   this.selected5 = { value: '', text: '선택' }
    },
    updateOption1() {
      this.option1 = this.fetchGameSetMenu
        .filter(item => item.useYn === 'Y' && item.type === 'sports')
        .map(item => ({
          value: item.name,
          text: item.nameCustom,
        }))

      // 배열의 시작 부분에 "선택" 옵션 추가
      this.option1.unshift({ value: '', text: '선택' })
    },
    updateOption2() {
      console.log(this.selected1.value)
      this.option2 = this.fetchGameSetItem
        .filter(item => item.useYn === 'Y' && item.type === 'sports' && item.menuName === this.selected1.value)
        .map(item => ({
          value: item.name,
          text: item.nameCustom,
        }))

      // 배열의 시작 부분에 "선택" 옵션 추가
      this.option2.unshift({ value: '', text: '선택' })
    },
    updateOption3() {
      console.log('this.selected2.value ::', this.selected2.value)
      this.option3 = this.fetchGameSetOddsCustom1
        .filter(item => item.type === 'sports' && item.menuName === this.selected1.value && item.itemName === this.selected2.value)
        .map(item => ({
          value: item.oddsType,
          text: item.oddsTypeName,
        }))

      // 배열의 시작 부분에 "선택" 옵션 추가
      this.option3.unshift({ value: '', text: '선택' })
    },
    updateOption4() {
      console.log('this.fetchGameSetOddsCustom2 ::', this.fetchGameSetOddsCustom2)
      this.option4 = this.fetchGameSetOddsCustom2
        .filter(item => item.type === 'sports' && item.menuName === this.selected1.value && item.itemName === this.selected2.value && item.oddsType !== this.selected3.value)
        .map(item => ({
          value: item.oddsType,
          text: item.oddsTypeName,
        }))

      // 배열의 시작 부분에 "선택" 옵션 추가
      this.option4.unshift({ value: '', text: '선택' })
    },
    updateSelected1(selectedValue) {
      const selectedOption = this.option1.find(option => option.value === selectedValue)
      if (selectedOption) {
        this.selected1 = {
          value: selectedOption.value,
          text: selectedOption.text,
        }
      }
    },
    updateSelected2(selectedValue) {
      const selectedOption = this.option2.find(option => option.value === selectedValue)
      if (selectedOption) {
        this.selected2 = {
          value: selectedOption.value,
          text: selectedOption.text,
        }
      }
    },
    updateSelected3(selectedValue) {
      const selectedOption = this.option3.find(option => option.value === selectedValue)
      if (selectedOption) {
        this.selected3 = {
          value: selectedOption.value,
          text: selectedOption.text,
        }
      }
    },
    updateSelected4(selectedValue) {
      const selectedOption = this.option4.find(option => option.value === selectedValue)
      if (selectedOption) {
        this.selected4 = {
          value: selectedOption.value,
          text: selectedOption.text,
        }
      }
    },
    updateSelected5(selectedValue) {
      const selectedOption = this.option5.find(option => option.value === selectedValue)
      if (selectedOption) {
        this.selected5 = {
          value: selectedOption.value,
          text: selectedOption.text,
        }
      }
    },
  },
}
</script>

<style>
.highlight-row {
  background-color: #464251 !important; /* 원하는 하이라이트 색상으로 변경 */
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
