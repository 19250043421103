<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="font-small-3"
            label="기준 사이트"
            label-for="site-select"
            style="max-width: 500px"
          >
            <b-form-select
              id="site-select"
              v-model="siteSelected"
              :options="authSite"
              class="font-small-3"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-tabs
        ref="tabs"
        content-class="mt-2"
        @input="onTabChange"
      >
        <b-tab
          title="기본설정"
          active
        >
          <GameSettingsTabBasic
            ref="basicTab"
            :site="siteSelected"
          />
        </b-tab>
        <b-tab title="메뉴설정">
          <GameSettingsTabMenu
            ref="menuTab"
            :site="siteSelected"
          />
        </b-tab>
        <b-tab title="종목설정">
          <GameSettingsTabItem
            ref="itemTab"
            :site="siteSelected"
          />
        </b-tab>
        <b-tab title="리그관리">
          <GameSettingsTabLeague
            ref="leagueTab"
            :site="siteSelected"
          />
        </b-tab>
        <b-tab title="팀관리">
          <GameSettingsTabTeam
            ref="teamTab"
            :site="siteSelected"
          />
        </b-tab>
        <b-tab title="게임유형설정">
          <GameSettingsTabOddsMarket
            ref="oddsMarketTab"
            :site="siteSelected"
          />
        </b-tab>
        <b-tab title="배당상세설정">
          <GameSettingsTabOddsCustom
            ref="oddsCustomTab"
            :site="siteSelected"
          />
        </b-tab>
        <b-tab title="조합설정">
          <GameSettingsTabCombination
            ref="combinationTab"
            :site="siteSelected"
          />
        </b-tab>
        <b-tab title="조합설정(New)">
          <GameSettingsTabCombination2
            ref="combinationTab2"
            :site="siteSelected"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import GameSettingsTabBasic from '@/views/game/gameSubTabs/GameSettingsTabBasic'
import GameSettingsTabMenu from '@/views/game/gameSubTabs/GameSettingsTabMenu'
import GameSettingsTabItem from '@/views/game/gameSubTabs/GameSettingsTabItem'
import GameSettingsTabLeague from '@/views/game/gameSubTabs/GameSettingsTabLeague'
import GameSettingsTabTeam from '@/views/game/gameSubTabs/GameSettingsTabTeam'
import GameSettingsTabOddsMarket from '@/views/game/gameSubTabs/GameSettingsTabOddsMarket'
import GameSettingsTabOddsCustom from '@/views/game/gameSubTabs/GameSettingsTabOddsCustom'
import GameSettingsTabCombination from '@/views/game/gameSubTabs/GameSettingsTabCombination'
import GameSettingsTabCombination2 from '@/views/game/gameSubTabs/GameSettingsTabCombination2'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    GameSettingsTabBasic,
    GameSettingsTabMenu,
    GameSettingsTabItem,
    GameSettingsTabLeague,
    GameSettingsTabTeam,
    GameSettingsTabOddsMarket,
    GameSettingsTabOddsCustom,
    GameSettingsTabCombination,
    GameSettingsTabCombination2,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      siteSelected: localStorage.getItem('authSiteSelected'),
    }
  },
  watch: {
    siteSelected: {
      handler(event) {
        this.siteSelected = event
        localStorage.setItem('authSiteSelected', event)
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
        // 선택된 사이트가 변경되면 현재 활성 탭의 데이터를 다시 로드합니다.
      },
      immediate: false,
      deep: false,
    },
  },
  created() {
  },
  methods: {
    onTabChange(newTabIndex) {
      // 탭 변경 시 해당 탭의 데이터를 로드합니다.
      switch (newTabIndex) {
        case 0:
          this.$refs.basicTab.fetchData()
          break
        case 1:
          this.$refs.menuTab.fetchData()
          break
        case 2:
          this.$refs.itemTab.fetchData()
          break
        case 3:
          this.$refs.leagueTab.fetchData()
          break
        case 4:
          this.$refs.teamTab.fetchData()
          break
        case 5:
          this.$refs.oddsMarketTab.fetchData()
          break
        case 6:
          this.$refs.oddsCustomTab.fetchData()
          break
        case 7:
          this.$refs.combinationTab.fetchData()
          break
        case 8:
          this.$refs.combinationTab2.fetchData()
          break
        default:
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
