<template>
  <div>
    <b-card style="margin-bottom: -1rem">
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        게임 팀관리
        <b-badge
          variant="light-primary"
          style="font-size: small; margin-left: 5px; margin-right: 3px"
          class="badge-glow"
        >
          {{ totalRows }}건
        </b-badge>
        <b-badge
          variant="primary"
          style="font-size: xx-small"
        >
          DB 연계
        </b-badge>
      </h4>
      <hr>
      <div class="m-1">
        <!-- Per Page -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="3" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              size="sm"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col cols="9" md="6" sm="12" class="mb-sm-1 d-flex justify-content-between align-items-center">
            <div class="d-inline-flex">
              <b-form-group class="mb-0">
                <b-form-checkbox
                  v-model="filterByNoCustomName"
                  inline
                  @change="fetchData"
                >
                  <div class="font-small-3">
                    이름(변경) 미설정 팀만 보기
                  </div>
                </b-form-checkbox>
              </b-form-group>
            </div>
            <div
              class="editClass ml-5"
              @click.stop
            >
              <span>
                <b-button
                  variant="primary"
                  class="mr-1"
                  size="sm"
                  v-if="!complete"
                >
                  <b-spinner small/>
                  리그명 생성중...
                </b-button>
                <b-button
                  v-if="complete"
                  ref="submitCreate"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="success"
                  class="mr-1"
                  style="float: right"
                  size="sm"
                  @click="submitUpdate('updateGameSetTeamCustomName')"
                >
                  리그명 동기화
                </b-button>
              </span>
            </div>
          </b-col>
          <b-col
            cols="12" md="6"
          >
            <b-button size="sm" class="" style="margin-right: 5px" :variant="selectedButton === '1' ? 'primary' : 'outline-secondary'" @click="menuSelect('soccer', '1')">축구</b-button>
            <b-button size="sm" class="" style="margin-right: 5px" :variant="selectedButton === '2' ? 'primary' : 'outline-secondary'" @click="menuSelect('basketball', '2')">농구</b-button>
            <b-button size="sm" class="" style="margin-right: 5px" :variant="selectedButton === '3' ? 'primary' : 'outline-secondary'" @click="menuSelect('baseball', '3')">야구</b-button>
<!--            <b-button size="sm" class="" style="margin-right: 5px" :variant="selectedButton === '4' ? 'primary' : 'outline-secondary'" @click="menuSelect('tennis', '4')">테니스</b-button>-->
            <b-button size="sm" class="" style="margin-right: 5px" :variant="selectedButton === '5' ? 'primary' : 'outline-secondary'" @click="menuSelect('volleyball', '5')">배구</b-button>
            <b-button size="sm" class="" style="margin-right: 5px" :variant="selectedButton === '6' ? 'primary' : 'outline-secondary'" @click="menuSelect('football', '6')">풋볼</b-button>
            <b-button size="sm" class="" style="margin-right: 5px" :variant="selectedButton === '7' ? 'primary' : 'outline-secondary'" @click="menuSelect('hockey', '7')">하키</b-button>
            <b-button size="sm" class="" style="margin-right: 5px" :variant="selectedButton === '8' ? 'primary' : 'outline-secondary'" @click="menuSelect('esports', '8')">E스포츠</b-button>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-col
                  cols="3"
                  md="5"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                  <label>검색옵션</label>
                  <b-form-select
                    v-model="searchOption"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="searchOptionTitle"
                    size="sm"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                  />
                </b-col>
                <b-form-input
                  v-model="inputValue"
                  type="search"
                  placeholder="Type to Search"
                  @keyup.enter="fetchData"
                />
                <b-input-group-append>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="ml-1"
                    @click="fetchData"
                  >
                    검색
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive
        show-empty
        hover
        small
        striped
        empty-text="No matching records found"
        :per-page="perPage"
        :items="filteredTeam"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterOn"
        style="text-align: center !important; font-size: small"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :current-page="currentPage"
        @filtered="onFiltered"
      >
        <template #cell(index)="data">
          {{ totalRows - (perPage * (currentPage - 1) + data.index) }}
        </template>
        <template #cell(country)="data">
          <div style="text-align: left; min-width: 10rem;">
            <img
              class="league-logo-img"
              style="width: 28px; margin-right: 5px;"
              :src="`https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_league_full_name_svg/${data.item.country}.svg`"
              @error="handleImageError"
            >
            <span
              style="text-align: left"
            >
              {{ data.item.country }}
            </span>
          </div>
        </template>
        <template #cell(leagueId)="data">
          <div
            style="min-width: 3rem"
          >
            {{ data.item.leagueId }}
          </div>
        </template>
        <template #cell(leagueName)="data">
          <div
            style="text-align: left"
          >
            {{ data.item.leagueName }}
          </div>
        </template>
        <template #cell(teamId)="data">
          <div
            style="min-width: 3rem"
          >
            {{ data.item.teamId }}
          </div>
        </template>
        <template #cell(teamName)="data">
          <div
            style="text-align: left"
          >
            {{ data.item.teamName }}
          </div>
        </template>
        <template #cell(teamNameCustom)="data">
          <b-form-input
            :value="data.item.teamNameCustom"
            style="text-align: left; min-width: 7rem; height: 2rem; color:rgb(229,166,48); font-size: small"
            @change="updateParam('updateGameSetTeam', 'fetchGameSetTeam', 'teamNameCustom', $event, data.item)"
          />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <!-- submit and reset -->
      <b-col
        id="basic1-btn"
        class="mt-1"
        cols="12"
      >
        <b-button
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="w-auto content-right"
          size="sm"
          @click="submit('updateGameSetTeam')"
        >
          저장
        </b-button>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BPagination,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BBadge,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'

import { FETCH_GAME_SET_TEAM } from '@/store/game/action'
import {UPDATE_GAME_SET_TEAM, UPDATE_GAME_SET_TEAM_CUSTOM_NAME} from '@/store/game/mutation'

const gameStore = createNamespacedHelpers('gameStore')

export default {
  name: 'GameSettingsTabTeam',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BPagination,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BBadge,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    site: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      selectSports: 'soccer',
      selectedButton: '1',
      complete: true,
      perPage: 100,
      pageOptions: [50, 100, 500],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 1,
      fields: [
        { key: 'index', sortable: false, label: 'No.' },
        { key: 'sports', sortable: false, label: '종목명' },
        { key: 'country', sortable: false, label: '국가' },
        { key: 'leagueId', sortable: false, label: '리그코드' },
        { key: 'leagueName', sortable: false, label: '리그명' },
        { key: 'leagueNameCustom', sortable: false, label: '리그명(설정값)' },
        { key: 'teamId', sortable: false, label: '팀코드' },
        { key: 'teamName', sortable: false, label: '팀명' },
        { key: 'teamNameCustom', sortable: false, label: '팀명(변경)' },
      ],
      filterByNoCustomName: false,
      inputValue: '',
      searchOption: null,
      searchOptionTitle: [
        { value: null, text: '선택' },
        { value: 'sports', text: '종목명' },
        { value: 'country', text: '국가' },
        { value: 'leagueId', text: '리그코드' },
        { value: 'leagueName', text: '리그명' },
        { value: 'leagueNameCustom', text: '리그명(설정값)' },
        { value: 'teamId', text: '팀코드' },
        { value: 'teamName', text: '팀명' },
        { value: 'teamNameCustom', text: '팀명(변경)' },
      ],
    }
  },
  computed: {
    ...gameStore.mapGetters({
      fetchGameSetTeam: 'fetchGameSetTeam',
      updateGameSetTeam: 'updateGameSetTeam',
      updateGameSetTeamCustomName: 'updateGameSetTeamCustomName',
    }),
    filteredTeam() {
      let filteredItems = this.fetchGameSetTeam

      // 기본 텍스트 검색
      if (this.searchFilter) {
        filteredItems = filteredItems.filter(item => Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      }
      // '이름(변경) 미설정 팀만 보기'가 활성화된 경우 nameCustom = null 인 항목만 필터링
      // if (this.filterByNoCustomName) {
      //   filteredItems = filteredItems.filter(item => item.nameCustom === null || item.nameCustom === '')
      // }

      this.totalRows = filteredItems.length
      return filteredItems
    },
  },
  watch: {
    site: {
      handler(event) {
        this.fetchData()
        // localStorage.setItem('authSiteSelected', event)
        // this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
      },
      immediate: false,
      deep: false,
    },
  },
  created() {
  },
  mounted() {
    // this.fetchData()
  },
  setup() {
  },
  methods: {
    ...gameStore.mapActions({
      $fetchGameSetTeam: FETCH_GAME_SET_TEAM,
      $updateGameSetTeam: UPDATE_GAME_SET_TEAM,
      $updateGameSetTeamCustomName: UPDATE_GAME_SET_TEAM_CUSTOM_NAME,
    }),
    updateParam(t, f, e, v, i) {
      // console.log(t)
      // console.log(f)
      // console.log(e)
      // console.log(v)
      // console.log(i)
      const events = {
        t, f, e, v, i,
      }
      // store에 param을 update 한다
      this.$store.commit('gameStore/updateManyTeamParam', events)
    },
    async menuSelect(sports, index) {
      this.selectSports = sports
      this.selectedButton = index
      this.searchOption = null
      this.inputValue = ''
      await this.fetchData()
    },
    async fetchData() {
      const mySite = this.site
      const { searchOption } = this
      const { inputValue } = this
      await this.$fetchGameSetTeam({
        site: mySite,
        searchOption,
        searchOptionValue: inputValue,
        teamNameCustom: this.filterByNoCustomName,
        sports: this.selectSports,
      })
      this.totalRows = this.fetchGameSetTeam.length
      // console.log('fetchGameSetTeam:::', this.fetchGameSetTeam)
    },
    async submit(target) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target)
        }
      })
    },
    async submitUpdate(target, data) {
      await Swal.fire({
        title: '리그명 정보를 업데이트 하시겠습니까?',
        // text: '팀 정보는 설정된 리그 기반으로 생성됩니다. 리그의 사용 여부를 설정하시기 바랍니다. 팀 정보 생성에는 사용 리그에 따라 최대 30분 소요됩니다',
        html: "<h5 style='color:rgb(78,238,62)'>" + "리그관리에서 설정한 리그명(변경) 정보를 팀에 업데이트 합니다." + "</h5>",
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
          text: 'swal-text-custom',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target, data)
        }
      })
    },
    // confirm Yes
    async confirmYes(target) {
      const mySite = this.site
      try {
        if (target === 'updateGameSetTeam') {
          await this.$updateGameSetTeam({
            site: mySite,
            // store의 updated 된 param을 사용한다.
          })
        }
        if (target === 'updateGameSetTeamCustomName') {
          this.complete = false
          await this.$updateGameSetTeamCustomName({
            site: mySite,
            sports: this.selectSports,
          })
          console.log('updateGameSetTeamCustomName :: ', this.updateGameSetTeamCustomName)
        }
      } catch {
        console.error()
      } finally {
        if (target === 'updateGameSetTeamCustomName') {
          this.complete = true
          Swal.fire({
            title: '리그명 정보가 업데이트 완료되었습니다',
            html: "<h5 style='color:rgb(78,238,62)'>" + this.updateGameSetTeamCustomName + '건 업데이트' + "</h5>",
            icon: 'success',
            timer: 5000,
            background: 'rgb(3,11,37)',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(async result => {
            await this.fetchData()
          })
        } else {
          Swal.fire({
            title: '저장 완료',
            icon: 'success',
            timer: 5000,
            background: 'rgb(3,11,37)',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(result => {
            this.fetchData()
          })
        }
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    handleImageError(e) {
      e.target.src = 'https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_league_full_name_svg/default.svg'
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
